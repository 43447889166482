@import '@/styles/variables';

.header {
  flex: 1 1;
  margin-bottom: 0.5rem;
  text-overflow: ellipsis;
  font-size: 30px;

  @include media-breakpoint-up(sm) {
    font-size: 40px;
  }

  a {
    color: var(--#{$prefix}heading-color);

    &:hover {
      text-decoration: underline;
    }
  }
}
